<template>
    <div class="user-homepage">
        <div class="user-homepage-header">
            <div class="user-base-info">
                <div class="user-profile">
                    <avatar
                        class="user-avatar"
                        size="mg"
                        :src="userInfo.avatar"
                        :userId="userId"
                        :enableCard="false"
                        :enableLink="false"
                    ></avatar>
                    <div class="user-profile-main">
                        <h3 :title="`${userInfo.realName}@${userInfo.nickName}`">
                            {{userInfo.realName}}
                            <span>@{{userInfo.nickName}}</span>
                        </h3>
                        <p class="profile-info">{{userInfo.firmName}} {{userInfo.title}}</p>
                        <p class="profile-slogan">{{userInfo.slogan || '这家伙很懒，什么也没留下'}}</p>
                    </div>
                </div>
                <div class="user-data-show">
                    <div class="user-data-show-item">
                        <span>{{userInfo.followingsCount}}</span>
                        <i>关注</i>
                    </div>
                    <div class="user-data-show-item">
                        <span>{{userInfo.followersCount}}</span>
                        <i>粉丝</i>
                    </div>
                    <div class="user-data-show-item">
                        <span>{{userInfo.jobs}}</span>
                        <i>职位</i>
                    </div>
                </div>
            </div>
            <div class="user-btn">
                <el-button 
                    v-if="!userInfo.isFollowedByMe" 
                    class="follow-btn" 
                    type="primary" 
                    icon="el-icon-plus" 
                    @click="handleFollow"
                    :loading="followBtnLoading"
                >关注</el-button>
                <el-button 
                    v-else 
                    class="follow-btn" 
                    type="primary" 
                    plain 
                    icon="el-icon-check" 
                    @click="handleUnFollow"
                    :loading="followBtnLoading"
                >已关注</el-button>
            </div>
        </div>

        <div class="user-homepage-container">
            <div class="user-homepage-left">
                <router-view
                    ref="contentView"
                    :userId="userId"
                    :userInfo="userInfo"
                    :hasViewPermission="hasViewPermission"
                ></router-view>
            </div>
            <div class="user-homepage-right">
                <div class="user-company">
                    <p><font-icon href="#icon-building-o"></font-icon>所在公司</p>
                    <div class="logo-container" :class="userInfo.logoUrl ? '' : 'logo-container-default'">
                        <img v-if="userInfo.logoUrl" :src="userInfo.logoUrl" alt="所在公司">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { user as userUrl } from '#/js/config/api.json';
import Avatar from '#/component/common/avatar.vue';

export default {
    components: {
        Avatar
    },
    data() {
        return {
            loading: false,
            leftLoading: false,
            rightLoading: false,
            hasViewPermission: false,
            userId: '',
            userInfo: {},
            tableData: [],
            industryTags: [],
            jobTags: [],
            customerTags: [],
            introduction: '',
            start: 0,
            take: 10,
            trendLoaded: false,
            trendTotalCount: 0,
            trendList: [],
            trendMoreLoading: false,
            followBtnLoading: false
        }
    },
    watch: {
        // 如果路由有变化，会再次执行该方法
        '$route': function() {
            this.$nextTick(() =>{
                this.$refs.contentView.init && this.$refs.contentView.init();
                this.routerTitle();
            })
        }
  },
    mounted() {
        this.userId = this.$route.params.userId;
        this.checkIfCanView();
    },
    methods: {
        routerTitle(){
            let pageName = '主页';
            if(this.$route.path.indexOf('jobs') > -1) {
                pageName = '职位';
            }
            if(this.$route.path.indexOf('candidates') > -1) {
                pageName = '候选人';
            }
            let title = `${this.userInfo.realName}的${pageName}_${this.userInfo.firmName}_猎必得_最有效的O2O猎头招聘平台`;
            document.title = title;
        },
        checkIfCanView() {
            this.leftLoading = true;
            this.rightLoading = true;
            _request({
                url: userUrl.has_view_permission.replace('%p', this.userId),
                method: 'GET'
            }).then(res => {
                this.hasViewPermission = true;
                this.$refs.contentView.init && this.$refs.contentView.init();
                this.getUserInfo();
            }).catch(err => {
                this.leftLoading = false;
                this.rightLoading = false;
            })
        },

        getUserInfo() {
            _request({
                url: userUrl.user_card.replace('%p', this.userId),
                method: 'GET'
            }).then(res => {
                res.slogan = res.slogan || '这家伙很懒，什么也没留下';
                res.isHunter = res.identityType === 2;
                this.userInfo = res;

                this.routerTitle();
            })
        },

        

        handleFollow() {
            if(this.followBtnLoading) {
                return false;
            }
            this.followBtnLoading = true;

            _request({
                url: userUrl.do_follow.replace('%p', this.userId),
                method: 'POST'
            }).then(res => {
                this.userInfo.isFollowedByMe = !this.userInfo.isFollowedByMe;
            }).finally(() => {
                this.followBtnLoading = false;
            })
        },

        handleUnFollow() {
            if(this.followBtnLoading) {
                return false;
            }
            this.followBtnLoading = true;

            _request({
                url: userUrl.do_unfollow.replace('%p', this.userId),
                method: 'POST'
            }).then(res => {
                this.userInfo.isFollowedByMe = !this.userInfo.isFollowedByMe;
            }).finally(() => {
                this.followBtnLoading = false;
            })
        },
    }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1400px) {
    .user-homepage-container {
        .user-homepage-left { 
            width: calc(100% - 370px) !important;
        }
        .user-homepage-right { 
            width: 350px !important;
        }
    }
}
@media screen and (min-width: 1400px) and (max-width: 1680px) {
    .user-homepage-container {
        .user-homepage-left { 
            width: calc(100% - 420px) !important;
        }
        .user-homepage-right { 
            width: 400px !important;
        }
    }
}

.user-homepage {
    padding: 20px;
    height: 100%;
    overflow-y: auto;

    &-header {
        margin-bottom: 20px;
        padding: 50px;
        background-color: #fff;
        border-radius: 8px;
        display: flex;

        .user-base-info {
            flex: 1;
            display: flex;
            justify-content: space-between;
            .user-profile {
                min-width: 400px;
                display: flex;
                padding-right: 70px;
                margin-right: 70px;
                border-right: 1px solid #eee;
                .user-avatar {
                    padding: 10px;
                    margin-right: 15px;
                    // flex-shrink: 0;
                    .avatar-mg {
                        width: 100px;
                        height: 100px;
                    }
                }

                &-main {
                    h3 {
                        font-size: 18px;
                        color: #333;
                        margin-bottom: 5px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;

                        span {
                            color: #666666;
                        }
                    }

                    .profile-info {
                        margin-bottom: 5px;
                        color: #999999;
                    }

                    .profile-slogan {
                        margin-bottom: 0;
                        font-size: 14px;
                        color: #38BC9D;
                    }
                }
            }

            .user-data-show {
                min-width: 200px;
                display: flex;
                justify-content: space-around;
                align-items: center;
                width: 100%;

                &-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    span {
                        font-size: 30px;
                        color: #333;
                    }

                    i {
                        font-size: 12px;
                        color: #666;
                    }
                }
            }
        }

        .user-btn {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            .follow-btn {
                width: 150px;
                height: 46px;
                line-height: 46px;
                padding: 0;
                font-size: 16px;

                &.is-plain {
                    color: $primary;
                    background-color: #fff;;
                }
            }
        }
    }

    &-container {
        display: flex;
        justify-content: space-between;
    }

    &-left {
        width: calc(100% - 490px);
        padding: 20px;
        background-color: #fff;
        border-radius: 8px;
    }

    &-right {
        width: 470px;
        margin-left: 20px;

        .user-company {
            background-color: #fff;
            border-radius: 8px;
            padding-bottom: 20px;
            p {
                padding: 0 20px;
                height: 56px;
                line-height: 56px;;
                margin: 0;
                border-bottom: 1px solid #eee;
            }
            .font-icon {
                width: 16px;
                height: 16px;
                fill: #b9b9b9;
                margin-right: 4px;
            }

            .logo-container {
                background-color: #f6fbfd;
                border: 1px solid #eee;
                margin: 20px 20px 0;

                img {
                    display: block;
                    margin: 2px auto;
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .logo-container-default {
                height: 50px;
                background-image: url('~@src/assets/images/default-company.png');
                background-repeat: no-repeat;
                background-position: 50%;
            }

            img {
                display: block;
                width: 100%;
            }
        }
    }
}
</style>