var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "user-homepage" }, [
    _c("div", { staticClass: "user-homepage-header" }, [
      _c("div", { staticClass: "user-base-info" }, [
        _c(
          "div",
          { staticClass: "user-profile" },
          [
            _c("avatar", {
              staticClass: "user-avatar",
              attrs: {
                size: "mg",
                src: _vm.userInfo.avatar,
                userId: _vm.userId,
                enableCard: false,
                enableLink: false,
              },
            }),
            _c("div", { staticClass: "user-profile-main" }, [
              _c(
                "h3",
                {
                  attrs: {
                    title: `${_vm.userInfo.realName}@${_vm.userInfo.nickName}`,
                  },
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.userInfo.realName) +
                      "\n                        "
                  ),
                  _c("span", [_vm._v("@" + _vm._s(_vm.userInfo.nickName))]),
                ]
              ),
              _c("p", { staticClass: "profile-info" }, [
                _vm._v(
                  _vm._s(_vm.userInfo.firmName) +
                    " " +
                    _vm._s(_vm.userInfo.title)
                ),
              ]),
              _c("p", { staticClass: "profile-slogan" }, [
                _vm._v(
                  _vm._s(_vm.userInfo.slogan || "这家伙很懒，什么也没留下")
                ),
              ]),
            ]),
          ],
          1
        ),
        _c("div", { staticClass: "user-data-show" }, [
          _c("div", { staticClass: "user-data-show-item" }, [
            _c("span", [_vm._v(_vm._s(_vm.userInfo.followingsCount))]),
            _c("i", [_vm._v("关注")]),
          ]),
          _c("div", { staticClass: "user-data-show-item" }, [
            _c("span", [_vm._v(_vm._s(_vm.userInfo.followersCount))]),
            _c("i", [_vm._v("粉丝")]),
          ]),
          _c("div", { staticClass: "user-data-show-item" }, [
            _c("span", [_vm._v(_vm._s(_vm.userInfo.jobs))]),
            _c("i", [_vm._v("职位")]),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "user-btn" },
        [
          !_vm.userInfo.isFollowedByMe
            ? _c(
                "el-button",
                {
                  staticClass: "follow-btn",
                  attrs: {
                    type: "primary",
                    icon: "el-icon-plus",
                    loading: _vm.followBtnLoading,
                  },
                  on: { click: _vm.handleFollow },
                },
                [_vm._v("关注")]
              )
            : _c(
                "el-button",
                {
                  staticClass: "follow-btn",
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-check",
                    loading: _vm.followBtnLoading,
                  },
                  on: { click: _vm.handleUnFollow },
                },
                [_vm._v("已关注")]
              ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "user-homepage-container" }, [
      _c(
        "div",
        { staticClass: "user-homepage-left" },
        [
          _c("router-view", {
            ref: "contentView",
            attrs: {
              userId: _vm.userId,
              userInfo: _vm.userInfo,
              hasViewPermission: _vm.hasViewPermission,
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "user-homepage-right" }, [
        _c("div", { staticClass: "user-company" }, [
          _c(
            "p",
            [
              _c("font-icon", { attrs: { href: "#icon-building-o" } }),
              _vm._v("所在公司"),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "logo-container",
              class: _vm.userInfo.logoUrl ? "" : "logo-container-default",
            },
            [
              _vm.userInfo.logoUrl
                ? _c("img", {
                    attrs: { src: _vm.userInfo.logoUrl, alt: "所在公司" },
                  })
                : _vm._e(),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }